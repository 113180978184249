'use client'
import Link from 'next/link'
import Image from 'next/image'
import { DEFAULT_LOGO } from './(store)/constants'
import { lato, oswald } from './(store)/assets/fonts'
import Error from 'next/error'

export default function GlobalError({ error }: { error: Error & { digest?: string } }) {
  return (
    <>
      <html>
        <body className={`relative ${lato.variable} ${oswald.variable}`}>
          <header className="bg-secondary py-2 lg:py-6">
            <div className="container flex items-center justify-between">
              <a href="/">
                <Image
                  src={DEFAULT_LOGO}
                  width={200}
                  height={75}
                  alt="Recteq Logo"
                  priority
                  className="w-full max-w-32 lg:max-w-40"
                />
              </a>
              <a href="/" className="p text-white">
                Go to home page
              </a>
            </div>
          </header>
          <main id="main">
            <section className="container mb-5 py-2">
              <h2 className="h2">500</h2>
              <p className="p uppercase">Internal Error</p>
              <div className="mb-5 mt-2 text-center">
                <h3 className="h3">it&lsquo;s not you... it&lsquo;s us.</h3>
                <p className="p mb-2">There was an unknown error on this page.</p>
                <Link href="/" className="p">
                  Go to the homepage.
                </Link>
                <span className="p text-sm">{error.digest}</span>
              </div>
            </section>
            <section className="container mb-5 pb-5">
              <div className="p mt-6">
                <h4 className="h4">order by phone:</h4>
                <p className="mb-4 mt-2 uppercase">
                  Having trouble ordering online? Give us a call. one of our customer service
                  representatives will be happy to assist you.
                </p>
                <h5 className="h4 mb-2">(706) 922-0890</h5>
                <p>Mon - Fri: 9am - 7pm ET</p>
                <p>Sat: 9am - 3pm ET</p>
              </div>
            </section>
          </main>
        </body>
      </html>
    </>
  )
}
